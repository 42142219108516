//import logo from './logo.svg';
import { Suspense, lazy, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import Navbar from "./Components/navbar";
import ScrollToTop from "./Components/ScrollToTop";
import Loading from "./Components/Loading";
import AOS from "aos";
import "aos/dist/aos.css";

// Pages
const Home = lazy(() => import("./Pages/Home"));
const Services = lazy(() => import("./Pages/Services"));
const Privacy = lazy(() => import("./Pages/Privacy"));
const NotFound = lazy(() => import("./Pages/NotFound"));
const Scheduler = lazy(() => import("./Pages/scheduler"));

// Components
const Footer = lazy(() => import("./Components/footer"));

function App() {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
    AOS.refresh();
  }, []);

  return (
    <ParallaxProvider>
      <Router>
        <ScrollToTop />
        <main className="App">
          <Navbar />

          <div className="Content">
            <Suspense fallback={<Loading />}>
              <div className="ContentWrapper">
                <Routes>
                  <Route index element={<Home />} />
                  <Route path="/schedule" element={<Scheduler />} />
                  <Route path="/services" element={<Services />} />
                  {/* <Route path="/about" element={<About />} /> */}
                  <Route path="/privacy" element={<Privacy />} />
                  <Route path="/*" element={<NotFound />} />
                </Routes>
              </div>
            </Suspense>

            <Footer />
          </div>
        </main>
      </Router>
    </ParallaxProvider>
  );
}

export default App;
