import { useState, forwardRef, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import BookingFrame from "./BookingFrame";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BookNowButton(props: any) {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");
  const [showBookingFrame, setContents] = useState(false);

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setContents(true);
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
    setContents(false);
  };

  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  if (showBookingFrame) {
    return (
      <Box sx={props.divStyle}>
        <Button
          onClick={handleClickOpen("paper")}
          variant={props.size ?? "contained"}
          color={props.size ?? "secondary"}
          sx={props.sx}
          size={props.size ?? ""}
        >
          {props.children ?? "Book Now"}
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="Book Online"
          aria-describedby="Book an appointment online"
          fullScreen={fullScreen}
          fullWidth={!fullScreen}
          maxWidth={false}
          TransitionComponent={Transition}
          sx={{ zIndex: "2000 !important" }}
        >
          <AppBar
            sx={{
              position: "relative",
              backgroundColor: "var(--primary)",
              zIndex: 2000,
            }}
          >
            <Toolbar>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Book Online
              </Typography>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent dividers={scroll === "paper"}>
              <BookingFrame appointmentType={props.appointmentType} />
          </DialogContent>
        </Dialog>
      </Box>
    );
  } else {
    return (
      <Box sx={props.divStyle}>
        <Button
          onClick={handleClickOpen("paper")}
          variant={props.size ?? "contained"}
          color={props.size ?? "secondary"}
          sx={props.sx}
          size={props.size ?? ""}
        >
          {props.children ?? "Book Now"}
        </Button>
      </Box>
    );
  }
}
