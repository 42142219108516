import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

export default function Loading() {
  return (
    <Stack alignItems="center" sx={{ position: "absolute", top: "50%", width: "100%" }}>
      <CircularProgress
        color="secondary"
        size={100}
        thickness={4}
        value={100}
      />
    </Stack>
  );
}
