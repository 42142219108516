import { useState, useEffect } from "react";

export default function BookingFrame(props) {
  const [url, setUrl] = useState("");

  useEffect(() => {
    const baseUrl = "https://app.squarespacescheduling.com/schedule.php?owner=26842940";
    let appointmentType = "";

    if (props.appointmentType !== undefined && props.appointmentType !== "") {
      appointmentType = `&appointmentType=category:${encodeURI(
        props.appointmentType
      )}`;

      setUrl(baseUrl + appointmentType);
    }
    else {
      setUrl(baseUrl);
    }
  }, [props.appointmentType]);

  return (
    <div style={{ zIndex: 2000 }}>
      <iframe
        src={url}
        title="Schedule Appointment"
        width="100%"
        height="800"
        frameBorder="0"
        loading="lazy"
      ></iframe>
      <script
        src="https://embed.acuityscheduling.com/js/embed.js"
        type="text/javascript"
      ></script>
    </div>
  );
}
