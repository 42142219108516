import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link, useLocation } from "react-router-dom";
import BookNowButton from "../Components/BookNowButton";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = "100%";
const navItems = [
  { label: "Home", path: "/" },
  { label: "Services", path: "/services" },
  { label: "FAQs", path: "/#faqSection" },
  // { label: "Schedule an Appointment", path: "/schedule" },
  // { label: "About", path: "/about" },
];

export default function DrawerAppBar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [navFontColor, setFontColor] = useState("#FFF");
  const [navBgColor, setBgColor] = useState("rgba(0,0,0,.15)");

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/schedule" || pathname === "/privacy") {
      setFontColor("#000");
      setBgColor("none");
    } else {
      setFontColor("#FFF");
      setBgColor("rgba(0,0,0,.15)");
    }
  }, [pathname]); // do this on route change

  const handleDrawerToggle = () => {
    if (pathname === "/schedule" || pathname === "/privacy") {
      setFontColor("#000");
      setBgColor("none");
    } else if (!mobileOpen) {
      setFontColor("#000");
      setBgColor("none");
    } else {
      setFontColor("#FFF");
      setBgColor("rgba(0,0,0,.15)");
    }
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerToggleIfOpen = () => {
    if (mobileOpen) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Divider />
      <List sx={{ mb: 10, pt: 15 }}>
        {navItems.map((item) => (
          <ListItem key={item.label}>
            <ListItemButton
              sx={{ textAlign: "center" }}
              component={Link}
              to={item.path}
            >
              <ListItemText
                primary={item.label}
                primaryTypographyProps={{ fontSize: "2rem" }}
              />
            </ListItemButton>
          </ListItem>
        ))}

        <ListItem>
          <ListItemButton
            sx={{ textAlign: "center" }}
            href="https://squareup.com/gift/MX9FBZCGWAG0R/order"
            target="_blank"
          >
            <ListItemText
              primary="Gift Cards"
              primaryTypographyProps={{ fontSize: "2rem" }}
            />
          </ListItemButton>
        </ListItem>
      </List>

      <BookNowButton
        size="large"
        sx={{
          py: 2,
          px: 5,
          mb: 5,
          color: "#FFF",
          boxShadow: "none",
          backgroundColor: "var(--primary)",
          "&:hover": {
            backgroundColor: "var(--primary)",
            boxShadow: "0 0 10px 5px rgba(145, 92, 182, .4)",
          },
        }}
      />
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        sx={{
          background: navBgColor,
          boxShadow: "none",
          position: "absolute",
          zIndex: 1500,
        }}
        role="navigation"
      >
        <Toolbar sx={{ color: navFontColor, width: "90%", margin: "auto" }}>
          <Typography
            variant="h5"
            sx={{
              display: { xs: "block !important" },
              textAlign: "left",
              flexGrow: { xs: 1, sm: 0 },
              fontFamily: "'Cinzel', serif !important",
              color: navFontColor,
              textDecoration: "none",
            }}
            onClick={handleDrawerToggleIfOpen}
            component={Link}
            to="/"
          >
            Massage by Angelique
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "block" },
              textAlign: "right",
            }}
          >
            {navItems.map((item) => (
              <Button
                key={item.label}
                sx={{
                  color: navFontColor,
                  fontSize: "1rem",
                  textTransform: "none",
                }}
                component={Link}
                to={item.path}
              >
                {item.label}
              </Button>
            ))}

            <Button
              sx={{
                color: navFontColor,
                fontSize: "1rem",
                textTransform: "none",
              }}
              target="_blank"
              href="https://squareup.com/gift/MX9FBZCGWAG0R/order"
            >
              Gift Cards
            </Button>
            <BookNowButton
              size="large"
              divStyle={{ display: "inline-flex" }}
              sx={{
                color: "#FFF",
                boxShadow: "none",
                mx: "10px",
                backgroundColor: "var(--primary)",
                "&:hover": {
                  backgroundColor: "var(--primary)",
                  boxShadow: "0 0 10px 5px rgba(145, 92, 182, .4)",
                },
              }}
            />
          </Box>
          <Button
            className={`navbar-toggler ${mobileOpen ? "rotate-cc" : ""}`}
            onClick={handleDrawerToggle}
            sx={{
              minWidth: "1rem",
              borderRadius: 8,
              justifyContent: "right",
              display: { xs: "inline-flex", sm: "none" },
              ml: 2,
            }}
          >
            <span
              className="navbar-toggler-icon"
              style={{ backgroundColor: navFontColor }}
            ></span>
            <span
              className="navbar-toggler-icon"
              style={{ backgroundColor: navFontColor }}
            ></span>
            <span
              className="navbar-toggler-icon"
              style={{ backgroundColor: navFontColor }}
            ></span>
          </Button>
        </Toolbar>
      </AppBar>
      <Box component="nav" role="navigation">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#FFF",
            },
          }}
          anchor="right"
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
